var render = function render(){var _vm=this,_c=_vm._self._c;return _c('SetupForm',_vm._g(_vm._b({attrs:{"title":_vm.$t('settings.automation.whenIWork.optionalSettings.title'),"next-text":_vm.$t('settings.automation.whenIWork.optionalSettings.nextText')},on:{"submit":_vm.updateSettings}},'SetupForm',_vm.$attrs,false),_vm.$listeners),[_c('RaiSetupToggle',{attrs:{"name":"clockInByLocation","title":_vm.$t(
        'settings.automation.whenIWork.optionalSettings.clockInByLocation.title'
      ),"subtitle":_vm.$t(
        'settings.automation.whenIWork.optionalSettings.clockInByLocation.subtitle'
      )},model:{value:(_vm.form.clockInByLocation),callback:function ($$v) {_vm.$set(_vm.form, "clockInByLocation", $$v)},expression:"form.clockInByLocation"}}),_c('RaiSetupToggle',{attrs:{"name":"useTimeclock","title":_vm.$t('settings.automation.whenIWork.optionalSettings.useTimeclock.title'),"subtitle":_vm.$t(
        'settings.automation.whenIWork.optionalSettings.useTimeclock.subtitle'
      )},model:{value:(_vm.form.useTimeclock),callback:function ($$v) {_vm.$set(_vm.form, "useTimeclock", $$v)},expression:"form.useTimeclock"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }