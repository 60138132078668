<template>
  <RaiSetups v-model="activeSetup" :loading="loading">
    <ConnectAccountSetup
      :automation="automation"
      :accounts="accounts"
      :type="completedSetups[0]"
      :store-number="currentStoreNumber"
      @add:account="onAccountAdd"
      @select:account="onAccountSelect"
      @update:automation="onAutomationUpdate"
      @sync:locations="onLocationsSync"
      @next="onNext"
    />
    <OptionalSettingsSetup
      :type="completedSetups[1]"
      :settings="automation.settings"
      :disabled="!enableOptionalSettings"
      @update:settings="onSettingsUpdate"
      @next="onNext"
    />
    <TrainTeamSetup />
    <ConnectAccountDialog ref="connectAccountDialog" @login="onAccountLogin" />
  </RaiSetups>
</template>

<script>
import { mapState } from "vuex";
import { subscribeOneOff } from "@/utils/graphql";
import { hasSnackbarAccess } from "@/mixins/snackbar";
import { RaiSetups, SetupType } from "@/core-ui";
import { useAccountView } from "./mixins/useAccountView";
import {
  ConnectAccountDialog,
  ConnectAccountSetup,
  OptionalSettingsSetup,
  TrainTeamSetup,
} from "./components/WhenIWork";

import {
  WHEN_I_WORK_ACCOUNT_ADD,
  WHEN_I_WORK_ACCOUNTS_SELECT_QUERY,
  WHEN_I_WORK_AUTOMATION_QUERY,
  WHEN_I_WORK_AUTOMATION_UPDATE,
  WHEN_I_WORK_LOCATIONS_SYNC,
  WHEN_I_WORK_LOCATIONS_SYNCED_SUBSCRIPTION,
  WHEN_I_WORK_ACCOUNT_ADD_SUBSCRIPTION,
  WHEN_I_WORK_ACCOUNT_SYNCED_SUBSCRIPTION,
} from "./graphql/WhenIWork";

export default {
  name: "WhenIWorkView",
  components: {
    RaiSetups,
    ConnectAccountSetup,
    ConnectAccountDialog,
    OptionalSettingsSetup,
    TrainTeamSetup,
  },
  mixins: [
    hasSnackbarAccess,
    useAccountView({
      prop: "whenIWorkAccounts",
      query: WHEN_I_WORK_ACCOUNTS_SELECT_QUERY,
    }),
  ],
  data: (vm) => ({
    activeSetup: 0,
    loading: false,
    automation: vm.buildDefaultAutomation(),
    completedSetups: new Array(3),
  }),
  apollo: {
    automation: {
      query: WHEN_I_WORK_AUTOMATION_QUERY,
      variables() {
        return { storeId: this.storeId };
      },
      update(response) {
        return response.whenIWorkAutomation || this.buildDefaultAutomation();
      },
      skip() {
        return !this.storeId;
      },
      watchLoading(isLoading) {
        this.setLoading(isLoading);
      },
    },
    $subscribe: {
      whenIWorkAccountAdded: {
        query: WHEN_I_WORK_ACCOUNT_ADD_SUBSCRIPTION,
        variables() {
          return { userId: this.userId, storeId: this.storeId };
        },
        skip() {
          return !this.userId || !this.storeId;
        },
      },
      whenIWorkAccountSynced: {
        query: WHEN_I_WORK_ACCOUNT_SYNCED_SUBSCRIPTION,
        variables() {
          return { userId: this.userId, storeId: this.storeId };
        },
        skip() {
          return !this.userId || !this.storeId;
        },
      },
      whenIWorkLocationsSynced: {
        query: WHEN_I_WORK_LOCATIONS_SYNCED_SUBSCRIPTION,
        variables() {
          return {
            storeId: this.storeId,
            integrationWhenIWorkId: this.automation?.integrationWhenIWorkId,
          };
        },
        skip() {
          return !this.storeId || !this.hasConnectedAccount;
        },
      },
    },
  },
  computed: {
    ...mapState("auth", { userId: "user_id" }),
    enableOptionalSettings() {
      const { whenIWorkLocationId } = this.automation || {};
      return this.hasConnectedAccount && whenIWorkLocationId > 0;
    },
    hasConnectedAccount() {
      return this.automation?.integrationWhenIWorkId > 0;
    },
  },
  methods: {
    buildDefaultAutomation() {
      return {
        integrationWhenIWorkId: null,
        whenIWorkLocationId: null,
        settings: {},
      };
    },
    setLoading(value) {
      this.loading = value;
    },
    onNext() {
      this.completedSetups[this.activeSetup] = SetupType.COMPLETE;
      this.activeSetup++;
    },
    onAccountAdd() {
      this.$refs.connectAccountDialog.show();
    },
    async onAccountLogin(context) {
      this.subscribeToAccountAdded(context);
      this.subscribeToAccountSynced(context);

      await this.$apollo.mutate({
        mutation: WHEN_I_WORK_ACCOUNT_ADD,
        variables: {
          storeId: this.storeId,
          input: context.values,
        },
      });
    },
    onAccountSelect(values) {
      if (!this.automation) {
        this.automation = {};
      }

      this.automation.integrationWhenIWorkId = values.integrationWhenIWorkId;
    },
    subscribeToAccountAdded(context) {
      subscribeOneOff(this, {
        name: "whenIWorkAccountAdded",
        resolve: (result) => {
          if (!result.success) {
            context.setErrors(result.errors);
            return;
          }

          const successText = this.$t(
            "settings.automation.whenIWork.connectAccount.addedSuccessText"
          );

          context.setStatus(successText);
        },
      });
    },
    subscribeToAccountSynced(context) {
      subscribeOneOff(this, {
        name: "whenIWorkAccountSynced",
        resolve: async (result) => {
          if (!result.success) {
            context.setErrors(result.errors);
            return;
          }

          const successText = this.$t(
            "settings.automation.whenIWork.connectAccount.syncedSuccessText"
          );

          context.setStatus(successText);
          this.showSnackbar({ text: successText });
          await this.$apollo.queries.accountList.refetch();
          context.resolve();
        },
      });
    },
    async onAutomationUpdate({ values, resolve }) {
      const { data } = await this.$apollo.mutate({
        mutation: WHEN_I_WORK_AUTOMATION_UPDATE,
        variables: {
          storeId: this.storeId,
          input: values,
        },
      });

      if (data.errors?.length > 0) {
        console.error("ERROR: WhenIWork Automation Update");
        return;
      }

      this.automation = {
        ...this.automation,
        ...values,
        ...data.whenIWorkAutomationUpdate.automation,
      };
      resolve();
    },
    async onSettingsUpdate({ values, resolve }) {
      const { data } = await this.$apollo.mutate({
        mutation: WHEN_I_WORK_AUTOMATION_UPDATE,
        variables: {
          storeId: this.storeId,
          input: { settings: values },
        },
      });

      if (data.errors?.length > 0) {
        console.error("ERROR: WhenIWork Settings Update");
        return;
      }

      this.automation = {
        ...this.automation,
        ...values,
        ...data.whenIWorkAutomationUpdate.automation,
      };
      resolve();
    },
    async onLocationsSync(context) {
      this.subscribeToLocationsSynced(context);

      await this.$apollo.mutate({
        mutation: WHEN_I_WORK_LOCATIONS_SYNC,
        variables: {
          storeId: this.storeId,
          integrationWhenIWorkId: context.values.integrationWhenIWorkId,
        },
      });
    },
    subscribeToLocationsSynced(context) {
      subscribeOneOff(this, {
        name: "whenIWorkLocationsSynced",
        resolve: async (result) => {
          this.showSnackbar({
            text: result.success
              ? this.$t(
                  "settings.automation.whenIWork.connectAccount.schedule.syncSuccessText"
                )
              : this.$t(
                  "settings.automation.whenIWork.connectAccount.schedule.syncErrorText"
                ),
          });

          await this.$apollo.queries.accountList.refetch();

          context.resolve();
        },
      });
    },
  },
};
</script>
