<template>
  <VxDialog
    v-model="visible"
    persistent
    no-back
    :v-size="'small'"
    :h-size="'xsmall'"
  >
    <template #innerDialog>
      <VxForm ref="form" @submit="login" @reset="onReset">
        <v-card>
          <v-card-text class="py-0">
            <v-img :src="logo" height="125" contain />
            <VxTextField
              v-model="form.username"
              :label="metadata.usernameInput.label"
              name="username"
              :placeholder="metadata.usernameInput.placeholder"
              type="text"
              rules="required"
            />
            <VxTextField
              v-model="form.password"
              :label="metadata.passwordInput.label"
              name="password"
              rules="required"
              :placeholder="metadata.passwordInput.placeholder"
              :type="passwordInputType"
              :append-icon="passwordVisiblityIcon"
              @click:append="passwordVisibilityToggle"
            />
            <v-fade-transition group>
              <VxAlert v-for="(error, idx) in errors" :key="idx" type="error">
                {{ error }}
              </VxAlert>
            </v-fade-transition>
          </v-card-text>
          <v-card-actions>
            <v-fade-transition>
              <span v-show="!!status" class="pl-2 text-caption text-truncate">
                {{ status }}
              </span>
            </v-fade-transition>
            <v-spacer />
            <VxBtn secondary @click="cancel">
              {{ metadata.cancelBtnText }}
            </VxBtn>
            <VxBtn :loading="submitting" @click="onSubmit">
              {{ metadata.loginBtnText }}
            </VxBtn>
          </v-card-actions>
        </v-card>
      </VxForm>
    </template>
  </VxDialog>
</template>

<script>
import { useFormObject } from "@/mixins/useFormObject";
import { VxDialog, VxForm, VxTextField, VxAlert, VxBtn } from "@/core-ui";

export default {
  name: "ConnectAccountDialog",
  components: { VxDialog, VxForm, VxTextField, VxAlert, VxBtn },
  mixins: [
    useFormObject({
      prop: "auth",
      default: {
        username: "",
        password: "",
      },
    }),
  ],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: (vm) => ({
    visible: !!vm.value,
    visiblePassword: false,
    submitting: false,
    errors: [],
    status: "",
  }),
  computed: {
    logo() {
      return require("@/assets/automation/when-i-work-logo.svg");
    },
    passwordInputType() {
      return this.visiblePassword ? "text" : "password";
    },
    passwordVisiblityIcon() {
      return this.visiblePassword ? "$visibilityOff" : "$visibilityOn";
    },
    metadata() {
      return this.$t("settings.automation.whenIWork.connectAccount.dialog");
    },
  },
  watch: {
    value(value) {
      this.visible = !!value;
    },
    visible(value) {
      !!value !== this.value && this.$emit("input", value);
    },
  },
  methods: {
    passwordVisibilityToggle() {
      this.visiblePassword = !this.visiblePassword;
    },
    show() {
      this.visible = true;
    },
    login(form) {
      this.submitting = true;
      this.$emit("login", {
        values: form.values,
        resolve: this.resolve,
        setErrors: this.setErrors,
        setStatus: this.setStatus,
      });
    },
    cancel() {
      this.resolve();
      this.$emit("cancel");
    },
    resolve() {
      this.visible = false;
      this.$refs.form.reset();
    },
    setErrors(errors) {
      this.submitting = false;
      this.errors = errors || [];
      this.status = "";
    },
    setStatus(status) {
      this.status = status;
    },
    onSubmit() {
      this.$refs.form.submit();
    },
    onReset() {
      this.status = "";
      this.errors = [];
      this.submitting = false;
      this.form.$reset();
    },
  },
};
</script>
